import React, { useEffect } from "react";
import classNames from "classnames";
import styles from "./Tab.module.less";
import { useTabContext } from "./Tabs";

interface Props {
  title: string;
  isDefault?: boolean;
  children: React.ReactNode;
}

function Tab({ title, children, isDefault = false }: Props) {
  const { isActive, selectTab } = useTabContext(children);

  useEffect(() => {
    if (!isDefault) return;
    selectTab();
  }, []);

  return (
    <button
      className={classNames(styles.tab, {
        [styles.active]: isActive,
      })}
      onClick={selectTab}
    >
      {title}
    </button>
  );
}

export default Tab;
