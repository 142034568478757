import styles from "./Button.module.less";

import { ReactNode } from "react";

interface Props {
  type: "button" | "submit" | "reset" | undefined;
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

function Button({ children, type, onClick, disabled }: Props) {
  return (
    <button
      type={type}
      className={styles.btn}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default Button;
