import styles from "./LoginForm.module.less";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { FormProvider, useForm } from "react-hook-form";

import { processLogin } from "processes/account/loginProcess";
import Button from "shared/components/Button";
import Loader from "shared/components/Loader";

import { selectAccountPending, selectAccountErrors } from "../selectors";
import FormInput from "shared/components/FormInput";

interface FormData {
  username: string;
  password: string;
}

function LoginForm() {
  const dispatch = useAppDispatch();
  const pending = useAppSelector(selectAccountPending);
  const errors = useAppSelector(selectAccountErrors);

  const form = useForm<FormData>();

  const handleLogin = useCallback(
    ({ username, password }: FormData) =>
      dispatch(
        processLogin({ username, password })
        //   username: "john.kolt@efstudios.org",
        //   password: "6!FUXcsv7sbQTaY",
      ),
    [dispatch]
  );

  return (
    <FormProvider {...form}>
      <form
        className={styles.container}
        onSubmit={form.handleSubmit(handleLogin)}
      >
        <FormInput
          id="username"
          type="text"
          placeholder="Логин (email)"
          options={{
            required: true,
          }}
        />
        <FormInput
          id="password"
          type="password"
          placeholder="Пароль"
          options={{
            required: true,
          }}
        />
        {errors && <div className={styles.error}>{errors}</div>}
        <Button type="submit" disabled={pending}>
          {pending && <Loader />}
          Войти
        </Button>
      </form>
    </FormProvider>
  );
}

export default LoginForm;
