// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Q_yfcX8fncO4SjLXkJs1 {\n  width: 100%;\n  font-family: \"Century Gothic\", \"__Akrobat_5\", \"Arial\";\n  border-radius: 0.5rem;\n  padding: 0.5rem 1rem;\n  border: 0;\n  color: var(--background-color);\n  background-color: rgba(var(--foreground-color--rgb), 0.5);\n}\n.Q_yfcX8fncO4SjLXkJs1::placeholder {\n  color: var(--background-color);\n}\n.bbX5YMZFN8GT2I4Ny3fO {\n  color: var(--secondary-color);\n}\n", "",{"version":3,"sources":["webpack://./src/shared/components/Input.module.less"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,qDAAA;EACA,qBAAA;EACA,oBAAA;EACA,SAAA;EACA,8BAAA;EACA,yDAAA;AADF;AAGE;EACE,8BAAA;AADJ;AAKA;EACE,6BAAA;AAHF","sourcesContent":["@import \"app/styles/const.less\";\n\n.input {\n  width: 100%;\n  font-family: @primaryFontName;\n  border-radius: 0.5rem;\n  padding: 0.5rem 1rem;\n  border: 0;\n  color: var(--background-color);\n  background-color: rgba(var(--foreground-color--rgb), 0.5);\n\n  &::placeholder {\n    color: var(--background-color);\n  }\n}\n\n.error {\n  color: var(--secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "Q_yfcX8fncO4SjLXkJs1",
	"error": "bbX5YMZFN8GT2I4Ny3fO"
};
export default ___CSS_LOADER_EXPORT___;
