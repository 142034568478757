/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./Account.module.less";
import { useAppDispatch, useAppSelector } from "store";
import { useCallback, useEffect, useState } from "react";
import { processLogout } from "processes/account/logoutProcess";
import { selectAccount } from "../selectors";
import { processCheckLogin } from "processes/account/checkLoginProcess";
import Modal from "shared/components/Modal";
import Tabs from "entities/tabs/Tabs";
import Tab from "entities/tabs/Tab";
import LoginForm from "./LoginForm";

function Account() {
  const dispatch = useAppDispatch();
  const account = useAppSelector(selectAccount);
  const [isModalShown, setIsModalShown] = useState(false);

  const handleShow = useCallback(() => setIsModalShown(true), []);
  const handleLogout = useCallback(() => dispatch(processLogout()), []);

  useEffect(() => {
    dispatch(processCheckLogin());
  }, []);

  if (!account) {
    return (
      <div className={styles.container}>
        <a className={styles.btn} onClick={handleShow}>
          Войти
        </a>
        <Modal isOpen={isModalShown} onClose={() => setIsModalShown(false)}>
          <Tabs>
            <Tab title="Вход" isDefault>
              <LoginForm />
            </Tab>
            <Tab title="Регистрация">
              <div>Регистрация sdsd</div>
            </Tab>
          </Tabs>
        </Modal>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <span className={styles.account}>{account.username}</span>
      <a className={styles.btn} onClick={handleLogout}>
        Выйти
      </a>
    </div>
  );
}

export default Account;
