// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".w7DmEftDZFXKcJsn7uvV {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  align-items: center;\n  justify-content: center;\n  padding: 1rem 0;\n  flex: 1;\n  margin: 0 3rem;\n}\n.k90G0DSADpiuHeUrfXDQ {\n  color: var(--secondary-color);\n}\n", "",{"version":3,"sources":["webpack://./src/features/account/components/LoginForm.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,OAAA;EACA,cAAA;AACF;AAEA;EACE,6BAAA;AAAF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  align-items: center;\n  justify-content: center;\n  padding: 1rem 0;\n  flex: 1;\n  margin: 0 3rem;\n}\n\n.error {\n  color: var(--secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "w7DmEftDZFXKcJsn7uvV",
	"error": "k90G0DSADpiuHeUrfXDQ"
};
export default ___CSS_LOADER_EXPORT___;
