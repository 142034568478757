// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dtTO6aaS2PrbjOo6rclB {\n  cursor: pointer;\n  color: var(--background-color);\n  padding: 0.5rem;\n}\n.YPyaXpxpTA1Dp2vsnums {\n  font-weight: bold;\n  color: var(--background-color);\n  font-size: 1rem;\n  text-shadow: 0px 0px 1rem var(--foreground-color);\n}\n._OpJBo6OhFZ8kzBcz0eq {\n  display: flex;\n  gap: 1rem;\n  align-items: center;\n  justify-content: end;\n  flex-grow: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/features/account/components/Account.module.less"],"names":[],"mappings":"AAEA;EACE,eAAA;EAEA,8BAAA;EAEA,eAAA;AAHF;AAcA;EACE,iBAAA;EACA,8BAAA;EACA,eAAA;EACA,iDAAA;AAZF;AAeA;EACE,aAAA;EACA,SAAA;EAEA,mBAAA;EACA,oBAAA;EACA,YAAA;AAdF","sourcesContent":["@import \"app/styles/const.less\";\n\n.btn {\n  cursor: pointer;\n  // border: 0;\n  color: var(--background-color);\n  // background-color: var(--primary-color);\n  padding: 0.5rem;\n  // border-radius: 0.5rem;\n\n  &:hover,\n  &:active,\n  &:focus {\n    // color: var(--foreground-color);\n    // background-color: var(--background-color);\n  }\n}\n\n.account {\n  font-weight: bold;\n  color: var(--background-color);\n  font-size: 1rem;\n  text-shadow: 0px 0px 1rem var(--foreground-color);\n}\n\n.container {\n  display: flex;\n  gap: 1rem;\n  // justify-content: center;\n  align-items: center;\n  justify-content: end;\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "dtTO6aaS2PrbjOo6rclB",
	"account": "YPyaXpxpTA1Dp2vsnums",
	"container": "_OpJBo6OhFZ8kzBcz0eq"
};
export default ___CSS_LOADER_EXPORT___;
