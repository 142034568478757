import styles from "./Input.module.less";

import { RegisterOptions, useFormContext } from "react-hook-form";

interface Props {
  id: string;
  type: string;
  placeholder?: string;
  options?: RegisterOptions;
}

function FormInput({ id, type, placeholder, options }: Props) {
  const form = useFormContext();
  const state = form.getFieldState(id);

  return (
    <>
      <input
        type={type}
        placeholder={placeholder}
        {...form.register(id, options)}
        className={styles.input}
      />
      {state.error && <div className={styles.error}>{state.error.message}</div>}
    </>
  );
}

export default FormInput;
