import styles from "./Modal.module.less";

import React, { useCallback, useState } from "react";
import Portal from "./Portal";
import classNames from "classnames";

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

const Modal = ({ children, isOpen, onClose }: Props) => {
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = useCallback(() => {
    if (isClosing) return;
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 250);
  }, [isClosing, onClose]);

  if (!isOpen) return null;
  return (
    <Portal>
      <div
        className={classNames(styles.modal, { [styles.closing]: isClosing })}
      >
        <button onClick={handleClose} className={styles.close}>
          &times;
        </button>
        {children}
      </div>
    </Portal>
  );
};

export default Modal;
