import styles from "./Tabs.module.less";
import React, {
  ReactNode,
  useEffect,
  useContext,
  useState,
  useCallback,
} from "react";

interface Props {
  children: ReactNode; // TODO: Tab!!!
}

type ActivateTabCallback = (tabId: string, tabContent: ReactNode) => void;

interface ContextType {
  activeTabId: string;
  activateTab: ActivateTabCallback;
}

export const TabsContext = React.createContext<ContextType>({
  activeTabId: "",
  activateTab: () => {},
});

export const useTabContext = (children: ReactNode) => {
  const [tabId] = useState<string>(crypto.randomUUID());
  const { activeTabId, activateTab } = useContext<ContextType>(TabsContext);

  // useEffect(() => {
  //   if (!context.activeTab) context.setActiveTab(tabId);
  //   return () => {};
  // }, [context, tabId]);

  const selectTab = useCallback(() => {
    activateTab(tabId, children);
  }, [activateTab, tabId, children]);

  return { isActive: activeTabId === tabId, selectTab };
};

function Tabs({ children }: Props) {
  const [activeTabId, setActiveTabId] = useState<string>("");
  const [activeTabContent, setActiveTabContent] =
    useState<ReactNode>(undefined);

  const activateTab = useCallback((tabId: string, tabContent: ReactNode) => {
    setActiveTabId(tabId);
    setActiveTabContent(tabContent);
  }, []);

  const [value, setValue] = useState<ContextType>({
    activeTabId,
    activateTab,
  });

  useEffect(() => {
    setValue({
      activeTabId,
      activateTab,
    });
  }, [activeTabId, activateTab]);

  console.log(value);

  return (
    <TabsContext.Provider value={value}>
      <div className={styles.container}>
        <div className={styles.tabs}>{children}</div>
        <div className={styles.content}>{activeTabContent}</div>
      </div>
    </TabsContext.Provider>
  );
}

export default Tabs;
