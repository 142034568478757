// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b5F8kjuGd9Fljr38Zbo8 {\n  cursor: pointer;\n  font-weight: bold;\n  font-family: \"Century Gothic\", \"__Akrobat_5\", \"Arial\";\n  border-radius: 0.5rem;\n  padding: 0.5rem 1rem;\n  color: var(--background-color);\n  background-color: var(--primary-color);\n  border: 0;\n  display: flex;\n  flex-direction: row;\n  gap: 0.5rem;\n}\n.b5F8kjuGd9Fljr38Zbo8:hover,\n.b5F8kjuGd9Fljr38Zbo8:active,\n.b5F8kjuGd9Fljr38Zbo8:focus {\n  color: var(--foreground-color);\n  background-color: var(--background-color);\n}\n.b5F8kjuGd9Fljr38Zbo8:disabled {\n  color: var(--background-color);\n  background-color: var(--foreground-color);\n}\n", "",{"version":3,"sources":["webpack://./src/shared/components/Button.module.less"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,iBAAA;EACA,qDAAA;EACA,qBAAA;EACA,oBAAA;EACA,8BAAA;EACA,sCAAA;EAEA,SAAA;EAEA,aAAA;EACA,mBAAA;EACA,WAAA;AAHF;AAKE;;;EAGE,8BAAA;EACA,yCAAA;AAHJ;AAME;EACE,8BAAA;EACA,yCAAA;AAJJ","sourcesContent":["@import \"app/styles/const.less\";\n\n.btn {\n  cursor: pointer;\n  font-weight: bold;\n  font-family: @primaryFontName;\n  border-radius: 0.5rem;\n  padding: 0.5rem 1rem;\n  color: var(--background-color);\n  background-color: var(--primary-color);\n\n  border: 0; //2px solid var(--background-color);\n\n  display: flex;\n  flex-direction: row;\n  gap: 0.5rem;\n\n  &:hover,\n  &:active,\n  &:focus {\n    color: var(--foreground-color);\n    background-color: var(--background-color);\n  }\n\n  &:disabled {\n    color: var(--background-color);\n    background-color: var(--foreground-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "b5F8kjuGd9Fljr38Zbo8"
};
export default ___CSS_LOADER_EXPORT___;
